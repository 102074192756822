import {Component, OnDestroy} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-abstract-form',
  templateUrl: './abstract-form.component.html',
  styleUrls: ['./abstract-form.component.scss']
})
export abstract class AbstractFormComponent implements OnDestroy {
  protected _subscription: Subscription = new Subscription();
  abstract formSubmitting: boolean;
  abstract formError: any;
  abstract formSuccess: boolean;
  abstract formGroup: FormGroup;

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  protected abstract _initForm(): void;

  abstract submitForm(): void;
}
