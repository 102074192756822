import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ConfirmationModalComponent} from '../_smart-components/confirmation-modal/confirmation-modal.component';
import {IConfirmationDialogData} from '../_models/confirmation-dialog-data.interfacel';
import {Observable} from 'rxjs';

@Injectable()
export class ConfirmationModalService {
  constructor(private readonly _matDialog: MatDialog) {
  }

  openDialog(data: IConfirmationDialogData): Observable<boolean> {
    return this._matDialog.open(ConfirmationModalComponent, {data}).afterClosed();
  }
}
