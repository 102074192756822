import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IConfirmationDialogData} from '../../_models/confirmation-dialog-data.interfacel';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent implements OnInit {

  constructor(
    private readonly _dialogRef: MatDialogRef<ConfirmationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmationDialogData
  ) {
  }

  ngOnInit(): void {
  }

  closeModal(success: boolean): void {
    this._dialogRef.close(success);
  }
}
