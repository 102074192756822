import {NgModule} from '@angular/core';
import { ConfirmationModalComponent } from './_smart-components/confirmation-modal/confirmation-modal.component';
import {CONFIRMATION_MODAL_SMART_COMPONENTS} from './_smart-components';
import {CONFIRMATION_MODAL_SERVICES} from './_services';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {CommonModule} from '@angular/common';
import {MatIconModule} from '@angular/material/icon';

@NgModule({
  declarations: [
    ...CONFIRMATION_MODAL_SMART_COMPONENTS
  ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatIconModule
    ],
  providers: [
    ...CONFIRMATION_MODAL_SERVICES
  ]
})
export class ConfirmationModalModule {}
